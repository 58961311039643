var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticClass:"add_goods",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$router.push("/extension/gatherTweeters/enterpriseGoodsAdd")}}},[_vm._v(" + 添加商品 ")]),(_vm.tabs_active == 'first')?_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"商品状态："}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.dataForm.status),callback:function ($$v) {_vm.$set(_vm.dataForm, "status", $$v)},expression:"dataForm.status"}},[_c('el-option',{attrs:{"label":"全部","value":0}}),_c('el-option',{attrs:{"label":"上架","value":1}}),_c('el-option',{attrs:{"label":"下架","value":-1}})],1)],1),_c('el-form-item',{attrs:{"label":" ","label-width":"15px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.get_data_list(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1):_vm._e(),_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":function () { return _vm.get_data_list(1); }},model:{value:(_vm.tabs_active),callback:function ($$v) {_vm.tabs_active=$$v},expression:"tabs_active"}},[_c('el-tab-pane',{attrs:{"label":"自营商品","name":"first"}}),_c('el-tab-pane',{attrs:{"label":"CPS商品","name":"second"}})],1),_c('el-table',{attrs:{"data":_vm.data_list,"header-cell-style":{ 'background-color': '#F8F9FA' },"row-key":"id"}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"title","label":"商品标题","align":"center"}}),_c('el-table-column',{attrs:{"prop":"sub_title","label":"副标题","align":"center"}}),_c('el-table-column',{attrs:{"label":"商品主图","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":row.head_imgs ? row.head_imgs[0] : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png',"preview-src-list":row.head_imgs}})]}}])}),(_vm.tabs_active == 'first')?_c('el-table-column',{attrs:{"label":"商品状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == -1)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.statusChange(row)}}},[_vm._v("上架")]):(row.status == 1)?_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.statusChange(row)}}},[_vm._v("下架")]):_vm._e()]}}],null,false,3141057918)}):_vm._e(),(_vm.tabs_active == 'second')?_c('el-table-column',{attrs:{"label":"商品状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status == -1 ? '下架' : '上架')+" ")]}}],null,false,1254071923)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"stock_num","label":"总库存","align":"center"}}),_c('el-table-column',{attrs:{"prop":"sales","label":"销量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"virtual_sales","label":"虚拟销量","align":"center"}}),_c('el-table-column',{attrs:{"label":"售卖价格","align":"center","prop":"sale_price"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.tabs_active == 'first')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editGoods(row)}}},[_vm._v("编辑")]):_vm._e(),(_vm.tabs_active == 'first')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.startCPS(row)}}},[_vm._v("CPS推广")]):_vm._e(),(_vm.tabs_active == 'first')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteGoods(row)}}},[_vm._v("删除")]):_vm._e(),(_vm.tabs_active == 'second')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.setHotFn(row)}}},[_vm._v(_vm._s(row.is_hot ? '取消爆款' : '设置爆款'))]):_vm._e(),(_vm.tabs_active == 'second')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteCpsGoods(row)}}},[_vm._v("删除")]):_vm._e()]}}])})],2),_c('Paging',{attrs:{"total":_vm.total_number,"page":_vm.dataForm.page,"pageNum":_vm.dataForm.rows},on:{"updatePageNum":_vm.updateData}}),_c('el-dialog',{attrs:{"title":"推广","visible":_vm.extensionDialogFlag,"width":"800px"},on:{"update:visible":function($event){_vm.extensionDialogFlag=$event}}},[_c('el-table',{attrs:{"data":_vm.setCpsGoods.skus_cps,"header-cell-style":{ 'background-color': '#F8F9FA' },"row-key":"id"}},[_c('el-table-column',{attrs:{"align":"center","label":"商品图片(800*800px)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.thumb_img)?_c('el-image',{staticStyle:{"width":"70px","height":"70px"},attrs:{"src":row.thumb_img}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"SKU属性值"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sku_attrs[0].attr_key)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"SKU库存","prop":"stock_num"}}),_c('el-table-column',{attrs:{"align":"center","label":"SKU价格"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","type":"number"},model:{value:(row.new_sale_price),callback:function ($$v) {_vm.$set(row, "new_sale_price", $$v)},expression:"row.new_sale_price"}})]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"SKU佣金"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","type":"number"},model:{value:(row.commission),callback:function ($$v) {_vm.$set(row, "commission", $$v)},expression:"row.commission"}})]}}])})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.extensionDialogFlag = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sureAddExtension}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }